// dynamic import
document.ready(() => {

  let pc = document.getElementById('page-config')
  if(!pc) return

  const source = pc.dataset.import
  if(!source) return

        if(source == 'dashboard')                   import('views/dashboard/index')
  else if(source == 'dashboard.missioncontrol')     import('views/dashboard/mission_control')
  else if(source == 'exceptions')                   import('views/exceptions/index')
  else if(source == 'exception.new')                import('views/exceptions/new')
  else if(source == 'exception.show')               import('views/exceptions/show')
  else if(source == 'hosts')                        import('views/hosts/index')
  else if(source == 'host.show')                    import('views/hosts/show')
  else if(source == 'risks')                        import('views/risks/index')
  else if(source == 'risk.show')                    import('views/risks/show')
  else if(source == 'services')                     import('views/services/index')
  else if(source == 'service.show')                 import('views/services/show')
  else if(source == 'tasks')                        import('views/tasks/index')
  else if(source == 'zones')                        import('views/zones/index')
  else if(source == 'zone.show')                    import('views/zones/show')
  else if(source == 'networks')                     import('views/networks/index')
  else if(source == 'networks.discovery')           import('views/networks/discovery.js')

  else if(source == 'account.log')                  import('views/account/log')
  else if(source == 'account.scheduler')            import('views/account/scheduler')

  else if(source == 'sessions')                     import('views/sessions/index')
  else if(source == 'session.mtan')                     import('views/sessions/mtan')

  else if(source == 'reports')                     import('views/reports/vulnerabilityReport')
  else if(source == 'reports.compare')                     import('views/reports/compare')
  else if(source == 'reports.performance')                     import('views/reports/performance')
  else if(source == 'reports.breakdown')                     import('views/reports/breakdown')
  else if(source == 'reports.bar')                     import('views/reports/bar')

  else if(source == 'settings.advanced')                     import('views/settings/advanced')
  else if(source == 'settings.api')                     import('views/settings/api')
  else if(source == 'settings.db')                     import('views/settings/db')
  else if(source == 'settings.layout')                     import('views/settings/layout.js')
  else if(source == 'settings.policies')                     import('views/settings/policies.js')

  else if(source == 'scanners')                     import('views/scanners/index.js')

  else if(source == 'policy.edit')                     import('views/policies/edit.js')

  else if(source == 'user.new')                     import('views/users/new.js')
  else if(source == 'user.edit')                     import('views/users/edit.js')
  else if(source == 'user.permissions')                     import('views/users/permissions.js')
  else if(source == 'user.password')                     import('views/users/password.js')

  else if(source == 'licenses')                     import('views/licenses/index.js')
  else if(source == 'license.order')                     import('views/licenses/order.js')
  else if(source == 'support')                     import('views/support/index.js')

  // backend & partner portal
  else if(source == 'backend')                      import('views/backend/index')
  else if(source == 'partner')                      import('views/partner/index')
})
