import $ from 'jquery'
import Rails from "@rails/ujs"

// override default javascript alerts
window.alert = function() {
    let $dialog = $('#alert-modal')
    let $ok = $dialog.find('#ok-button')
    let $content = $dialog.find('#modal-content')
    $content.text(arguments[0])
    $ok.on('click', () => {
        $dialog.modal('hide')
      })
    $dialog.modal('show')
  }
  
  // Overrides default rails confirmation with bootstrap confirm dialog
  // the modal is defined views/layouts/_flash.html.erb
  Rails.confirm = function(message, element) {
    let $element = $(element)
    let $dialog = $('#confirmation-modal')
    let $content = $dialog.find('#modal-content')
    let $ok = $dialog.find('#ok-button')
    $content.text(message)
    $ok.on('click', () => {
        $dialog.modal('hide')
        let old = Rails.confirm
        Rails.confirm = () => { return true }
        $element.get(0).click()
        Rails.confirm = old
      })
    $dialog.modal('show')
    return false
  }
  