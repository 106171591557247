// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//console.log('Hello from application.js')

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')

import $ from 'jquery'
window.$ = $      // otherwise dataTables won't work
window.jQuery = $ // otherwise bootstrap won't detect jquery (no events fired)

// must come before bootstrap
require("@popperjs/core")

// see https://getbootstrap.com/docs/5.1/getting-started/webpack/
import { Tooltip, Popover, Modal, Dropdown, Tabs } from "bootstrap"

require('datatables.net-bs5')

// jquery ui widgets
require('jquery-ui/ui/widgets/resizable')
require('jquery-ui/ui/widgets/tooltip')
require('jquery-ui/ui/widgets/draggable')

// jquery ui effects
require('jquery-ui/ui/effects/effect-highlight')

import 'bootstrap-datepicker'

import '@fortawesome/fontawesome-free/js/all'

// style-up rails confirmations dialog
require('./confirmations.js')

// custom scripts
require('./globals.js')  // should come first
require('./imports.js')  // dynamic imports depending on page-config div
