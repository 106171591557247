import consumer from "./consumer"
import { updateScanStartTime } from "../packs/globals"

//consumer.subscriptions.create("MessagesChannel", document.head.id, {
consumer.subscriptions.create({channel: "MessagesChannel", account: document.head.id }, {
  connected() {
    // Called when the subscription is ready for use on the server
    //console.log("MessagesChannel connected");
    //console.log(document.cookie)
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    //console.log("MessagesChannel disconnected");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log(data);
    var target = $('#progress_' + data.asset + '_' + data.id)
    target.width(data.progress + "%")
    target.html(data.progress + "%")

    let msg = "about " + data.remaining + " minutes remaining."
    if(data.remaining == 1) msg = "less than a minute remaining"

    let target_remaining = $('#remaining_' + data.asset + '_' + data.id)
    target_remaining.html(msg)

    // update start time (ago)
    updateScanStartTime()

    // reload on completion
    if(data.progress == 100) location.reload()
  }
});
