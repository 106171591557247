import consumer from "./consumer"
consumer.subscriptions.create({channel: "LoadingChannel", user: document.head.dataset.user }, {
  connected() {
    // Called when the subscription is ready for use on the server
    //console.log("loading channel connected")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log(data)

    var target = $('.progress-bar')
    target.width(data.progress + "%")
    target.html(data.progress + "%")
    if(data.message != undefined) {
        $('#loading_message').html(data.message)
    }
  }
});
