// gravity custom global functions
import $ from 'jquery'
import moment from 'moment'

document.getAuthToken = () => {
  try {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content")
  }
  catch {
    return '' // test env requires empty string
  }
}

export function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
    let results = regex.exec(location.search)
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "))
}

// called by messages_channel.js
export async function updateScanStartTime() {
  const start_time = $('#start_time').attr('data-start')
  $('#start_time').text(moment(start_time).fromNow())
}

// convenience callback jquery style
document.ready = (callback) => {
  if (document.readyState != 'loading') callback()
  else document.addEventListener('DOMContentLoaded', callback)
}

document.ready(() => {
  const meta = document.head.dataset

  // flash notice
  const flashElement = $("#flash_notice")
  flashElement.delay(200).fadeIn("fast", () => {
    flashElement.delay(2000).fadeOut("slow", (e) => { flashElement.remove() })
  })

  // menu: show active item
  $('#menu a').removeClass('active');
  $('.menu_item_' + meta.controller).addClass('active');

  // tooltip
  $('[data-bs-toggle="tooltip"]').tooltip()

  $('.time_ago').each((index, target) => {
    target.innerHTML = moment(target.innerHTML).fromNow()
  })

  $('.datepicker').datepicker({ format: "yyyy-mm-dd", weekStart: 1 })

  $('.modal').draggable()

  // enable link to tabs
  const hash = location.hash
  if (hash && hash.length > 1) {
    $(hash).tab('show')
    // reset hash in case of a reload
    // also avoids auto-scroll to item
    location.hash = ""
  }

  // set focus on inputs
  $('.focus').trigger('focus')

})
